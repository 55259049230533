
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import MenuTab from "@/components/common/MenuTab.vue";
import list from "@/components/common/list.vue";

import productNewCard from "@/components/common/productNewCard.vue";
import productCard from "@/components/common/productCard.vue";
import bnccProductCard from "@/components/common/bnccProductCard.vue";
import newsItem from "@/components/common/NewsItem.vue";
import TopicItem from "@/components/common/TopicItem.vue";
import ClassItem from "@/components/common/ClassItem.vue";
import Toast from "@/components/UI/Toast/";

//ts
import { standardMap, productMap, strainOrCellsMap } from "@/hooks/useProduct";
import $ from "jquery";

export default defineComponent({
  name: "OpenSearch",
  components: {
    MenuTab,
    productNewCard,
    list,
    newsItem,
    TopicItem,
    ClassItem,
  },
  setup() {
    //  切换列表菜单
   /* type Menu = "标物" | "标准" | "新闻" | "课堂" | "帖子" | "菌种&细胞";
    const menu: Menu[] = [
      "标物",
      "标准",
      "新闻",
      "课堂",
      "帖子",
      "菌种&细胞",
    ];*/
    type Menu = "产品" | "新闻" | "课堂" | "帖子";
    const menu: Menu[] = [
      "产品",
      "新闻",
      "课堂",
      "帖子",
    ];

    const route = useRoute();
    const type = route.query.type;
    let index = 0;
    if (type) {
      index = menu.findIndex((item) => {
        return item == type;
      });
    }

    const keyword = route.query.keyword;
    const currentMenuItem = ref(menu[index]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    /*const searchKw = ref(null);

    $.ajax({
      type: "get",
      url: "/M/Home/accurateSearch?keyword="+keyword,
      data:searchKw,
      processData: false,
      contentType: false,
      success: function (data)  {
        searchKw.value=data.success;
      },
      error: function (err) {
        // errorToast(err)
      }
    })*/
    if(localStorage.getItem('WX')){
      setTimeout(function () {
        location.reload();
      },1000)
    }
    localStorage.removeItem('WX')
    return {
      menu,
      currentMenuItem,
      selectMenu,
      index,
      standardMap,
      productMap,
      keyword,
      strainOrCellsMap,
    };
  },
});
