<template>
  <menu-tab
    :menu="menu"
    :swiper="true"
    :slidesPerView="4"
    :initIndex="index"
    @change="selectMenu"
  ></menu-tab>
<!--  <div v-show="!searchKw" class="kw_tip">
    没有找到与您搜索完全匹配的产品，以下是推荐产品或点击<a href="https://tb.53kf.com/code/client/a7c96e4ceb10e87d6fc792aec24104e05/3"  class="search_kefu">在线客服</a>进行咨询。
  </div>-->
    <!--<ul>
      <li v-for="(item) in searchKw.neiOrderList" :key="item.ono" class="product-li">
        <a href="">
          <div class="product-img">
            <img src="" alt="">
          </div>
          <div class="product-info-wrap">
              <div class="product-title">
                <span></span>
              </div>
          </div>
        </a>
      </li>
    </ul>-->

    <list
       url="/M/Home/OpenSearchMore"
      :filterFunc="productMap"
      :params="{ type: 'product', keyword: keyword }"
      :show="currentMenuItem == '产品'"
    >
      <template v-slot:default="data">
        <product-new-card :list="data" tid="0" name="productdetail" typeFlag="search"></product-new-card>
      </template>
    </list>
  <list url="/M/Home/OpenSearchMore" :params="{ type: 'news', keyword: keyword }" :show="currentMenuItem == '新闻'">
    <template v-slot:default="data">
      <news-item :newData="data"></news-item>
    </template>
  </list>
  <list url="/M/Home/OpenSearchMore" :params="{ type: 'topic', keyword: keyword }" :show="currentMenuItem == '帖子'">
    <template v-slot:default="data">
      <topic-item :topicData="data"></topic-item>
    </template>
  </list>
  <list url="/M/Home/OpenSearchMore" :params="{ type: 'course', keyword: keyword }" :show="currentMenuItem == '课堂'">
    <template v-slot:default="data">
      <class-item :course="data"></class-item>
    </template>
  </list>
<!--  <list
    url="/M/Home/OpenSearchMore"
    :params="{ type: 'bncc', keyword: keyword }"
    :filterFunc="strainOrCellsMap"
    :show="currentMenuItem == '菌种&细胞'"
  >
    <template v-slot:default="data">
      <bncc-product-card typeFlag="search" :list="data" name="bnccdetail"></bncc-product-card>
    </template>
  </list>-->
<!--  <list
    url="/M/Home/OpenSearchMore"
    :params="{ type: 'cells', keyword: keyword }"
    :filterFunc="strainOrCellsMap"
    :show="currentMenuItem == '细胞'"
  >
    <template v-slot:default="data">
      <bncc-product-card typeFlag="search" :list="data" name="bnccdetail"></bncc-product-card>
    </template>
  </list>-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import MenuTab from "@/components/common/MenuTab.vue";
import list from "@/components/common/list.vue";

import productNewCard from "@/components/common/productNewCard.vue";
import productCard from "@/components/common/productCard.vue";
import bnccProductCard from "@/components/common/bnccProductCard.vue";
import newsItem from "@/components/common/NewsItem.vue";
import TopicItem from "@/components/common/TopicItem.vue";
import ClassItem from "@/components/common/ClassItem.vue";
import Toast from "@/components/UI/Toast/";

//ts
import { standardMap, productMap, strainOrCellsMap } from "@/hooks/useProduct";
import $ from "jquery";

export default defineComponent({
  name: "OpenSearch",
  components: {
    MenuTab,
    productNewCard,
    list,
    newsItem,
    TopicItem,
    ClassItem,
  },
  setup() {
    //  切换列表菜单
   /* type Menu = "标物" | "标准" | "新闻" | "课堂" | "帖子" | "菌种&细胞";
    const menu: Menu[] = [
      "标物",
      "标准",
      "新闻",
      "课堂",
      "帖子",
      "菌种&细胞",
    ];*/
    type Menu = "产品" | "新闻" | "课堂" | "帖子";
    const menu: Menu[] = [
      "产品",
      "新闻",
      "课堂",
      "帖子",
    ];

    const route = useRoute();
    const type = route.query.type;
    let index = 0;
    if (type) {
      index = menu.findIndex((item) => {
        return item == type;
      });
    }

    const keyword = route.query.keyword;
    const currentMenuItem = ref(menu[index]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    /*const searchKw = ref(null);

    $.ajax({
      type: "get",
      url: "/M/Home/accurateSearch?keyword="+keyword,
      data:searchKw,
      processData: false,
      contentType: false,
      success: function (data)  {
        searchKw.value=data.success;
      },
      error: function (err) {
        // errorToast(err)
      }
    })*/
    if(localStorage.getItem('WX')){
      setTimeout(function () {
        location.reload();
      },1000)
    }
    localStorage.removeItem('WX')
    return {
      menu,
      currentMenuItem,
      selectMenu,
      index,
      standardMap,
      productMap,
      keyword,
      strainOrCellsMap,
    };
  },
});
</script>

<style lang="scss" scoped>
  a {
    color: #000;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  li {
    list-style: none;
  }

  .search_kefu{
    text-align: center;
    color: #df0024;
    font-size: 0.26rem;
    cursor: pointer;
    border-bottom: 1px solid #df0024;
  }
  .kw_tip{
    border: 1px dashed rgb(153, 153, 153);
    width: 9.4rem;
    margin: 0.3rem;
    height: 35px;
    line-height: 35px;
    padding-left: 8px;
    color: #666;
    font-size: 0.26rem;
  }

</style>
