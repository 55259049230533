
import {defineComponent, ref, isReactive, reactive, inject, watch} from "vue";
import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import {useRouter} from "vue-router";

export default defineComponent({
  props: {
    list: Object,
    name: String,
    tid: {
      type: String,
      default: "",
    },
    anclassId: {
      type: String,
      default: "",
    },
    typeFlag: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const userInfo = inject("userInfo") as any;
    const biaowuPriceOpen = ref()
    const router = useRouter();

    watch(
        () => {
          return props.list
        },
        (NewsVue) => {
          biaowuPriceOpen.value = (NewsVue as any).totalResult && (NewsVue as any).totalResult[0] && (NewsVue as any).totalResult[0].str
          //add 20230220 手机端未登录不展示价格
          if(!userInfo || !userInfo.id){
            biaowuPriceOpen.value =0;
          }
        }
    )

    //  add shop car
    const {addShopCar} = useShopCar();

    function back() {
      router.go(-1);
    }

    return {
      addShopCar,
      userInfo,
      biaowuPriceOpen,
      back
    };
  },
});
